.modal-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;
}

.modal-preview__dialog {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.modal-preview__content {
  border-radius: 0.2em;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  font-size: 1rem;
  overflow: hidden;
  position: relative;
}

.modal-preview__close {
  position: absolute !important;
  right: 20px;
  top: 0;
  z-index: 1001;
}

.modal-preview__btn {
  background-color: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  font-size: 16px;
  font-size: 1rem;
  height: 1em;
  min-height: initial;
  min-width: initial;
  width: 1em;
  padding: 1em 1.2em 1em 1.2em;
  position: absolute;
  right: 0;
  top: 0;
}

.modal-preview__x,
.modal-preview__x {
  height: 1em;
  left: 50%;
  position: absolute;
  top: 1em;
  width: 0.1em;
}

.modal-preview__x--1 {
  transform: rotate(45deg);
}

.modal-preview__x--2 {
  transform: rotate(-45deg);
}

.modal-preview__btn:focus {
  outline: none;
}

.modal-preview__iframe {
  border: none;
  height: 100%;
  width: 100%;
}
